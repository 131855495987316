import React from 'react';
import axios from "axios";
import axiosRetry from "axios-retry";
import {processGQResponse, processResponse} from './app.auth.lib';
import {authConfig} from "./app.auth.config";
import Cookies from "js-cookie";

const ROOT_URL = '';

let sourceAuthActionLogin = axios.CancelToken.source();

axiosRetry(axios, {
    retries: 2,
    retryCondition: (error) => {

        let retry = false;

        if(error?.response?.status === 419)
        {
            if(error?.config?.headers){
                error.config.headers.csrf = Cookies.get('csrftoken');
            }
            retry = true;
            //console.log("RETRY 419", error);
        }

        return retry;
    },
});


function CancelLastLoginRequest(){
    if(sourceAuthActionLogin){
        sourceAuthActionLogin.cancel();
    }
}



export function isPhoneCodeStage(){
    return
}

export async function registerUserPhone(dispatch, loginPayload){
    let result = {};
    try{

        dispatch({type:'REQUEST_LOGIN_PHONE'});
        sourceAuthActionLogin = axios.CancelToken.source();
        let config = {cancelToken: sourceAuthActionLogin.token};
        let bodyFormData = new FormData();
        bodyFormData.append("login",loginPayload.phone);

        let response = await axios.post(authConfig.urlRegPhone, bodyFormData, config);
        let response_data = await response;
        result = processResponse(response_data,loginPayload);

        if(result.data){
            console.log("SUCCESS_LOGIN_PHONE");
            dispatch({type:'SUCCESS_LOGIN_PHONE',payload:result});
            return result;
        }
        console.log('**** ERROR LOGIN_PHONE ****');
        dispatch({type:'LOGIN_PHONE_ERROR',error:result.error});
        return result;

    }catch(e){

        console.log('**** ERROR LOGIN_PHONE 2 ****');
        console.log(e);
        result.error = {code:e.code, msg:e.message, descr: e.description};
        dispatch({type:'LOGIN_PHONE_ERROR',error:result.error});

    }
    return result;
}

export async function registerUserPhoneCode(dispatch, loginPayload){

    // проверить что бы состояние было SUCCESS_LOGIN_PHONE
    // взять данные для отправки из состояния и из loginPayload

    let result = {};
    try {

        dispatch({type:'REQUEST_LOGIN_PHONECODE'});

        sourceAuthActionLogin = axios.CancelToken.source();
        let config = {cancelToken: sourceAuthActionLogin.token};
        let bodyFormData = new FormData();
        bodyFormData.append("login",loginPayload.phone);
        bodyFormData.append("code",loginPayload.passcode);
        bodyFormData.append("password","");
        bodyFormData.append('reg','1');
        let response = await axios.post(authConfig.urlRegPhoneCode, bodyFormData, config);
        let response_data = await response;
        result = processResponse(response_data,loginPayload);

        if(result && result.data){
            console.log("LOGIN_SUCCESS");
            dispatch({type: 'LOGIN_SUCCESS',payload:result});
            return result;
        }

        // если не наткнулись на return из условия выше

        console.log('**** ERROR LOGIN_PHONECODE ****');
        //console.log(result);
        //console.log("*******************************")
        dispatch({type: 'LOGIN_PHONECODE_ERROR',error:result.error});
        return result;

    }catch(e){

        console.log('**** ERROR LOGIN_PHONECODE 2 ****');
        result.error = {code:e.code, msg:e.message, descr: e.description};
        dispatch({type:'LOGIN_PHONECODE_ERROR',error:result.error});
    }

    return result;
}


// Запрос кода на авторизацию по номеру телефона
export async function loginUserPhone(dispatch, loginPayload){

    let result = {};
    try{

        dispatch({type:'REQUEST_LOGIN_PHONE'});
        sourceAuthActionLogin = axios.CancelToken.source();
        let config = {cancelToken: sourceAuthActionLogin.token};
        let bodyFormData = new FormData();
        bodyFormData.append("login",loginPayload.phone);

        let response = await axios.post(authConfig.urlLoginPhone, bodyFormData, config);
        let response_data = await response;
        result = processResponse(response_data,loginPayload);

        if(result.data){
            console.log("SUCCESS_LOGIN_PHONE");
            dispatch({type:'SUCCESS_LOGIN_PHONE',payload:result});
            return result;
        }
        console.log('**** ERROR LOGIN_PHONE ****');
        dispatch({type:'LOGIN_PHONE_ERROR',error:result.error});
        return result;

    }catch(e){

        console.log('**** ERROR LOGIN_PHONE 2 ****');
        console.log(e);
        result.error = {code:e.code, msg:e.message, descr: e.description};
        dispatch({type:'LOGIN_PHONE_ERROR',error:result.error});

    }
    return result;
}

/**
 * Преобразование принципал структуры в удобоворимый формат данных
 * @param result
 * @param dispatch
 * @returns {{data}|*}
 */
function buildPrincipal(result, dispatch){
    //console.log("buildPrincipal", result);

    // Три специфичных json массива распаковывает и переобзывает, остальные параметры берет как есть
    let data={};

    if (result?.data?.data) {
        if (!result.data.data.variablesJson){
            result.data.data.variablesJson = '[]'; // а почему бы и нет, зато везде будет валидный *.variables
        }
        for (let index in result.data.data) {
            let el = result.data.data[index];
            if (index == "rules") {
                data.parsedRules = JSON.parse(el);
            } else if (index == "fullRules") {
                data.parsedFullRules = JSON.parse(el);
            } else if (index == "rights") {
                data.parsedRights = JSON.parse(el);
            } else if (index == "properties") { // наверно это как раз то что я сделал ниже variables... но сделанные когда-то давно и хз как работающие
                data.parsedProperties = JSON.parse(el);
            } else if (index == "variablesJson") {
                data.variables = JSON.parse(el);
            } else {
                data[index] = el;
            }
        }
        ;
    }
    //console.log("buildPrincipal: ", data);

    result.data = {data};

    dispatch({type: 'LOGIN_SUCCESS',payload:result});
    return result;
}
// Авторизация по номеру телефона и смс/телеграмму коду
export async function loginUserPhoneCode(dispatch, loginPayload){


    // проверить что бы состояние было SUCCESS_LOGIN_PHONE
    // взять данные для отправки из состояния и из loginPayload

    let result = {};
    try {

        dispatch({type:'REQUEST_LOGIN_PHONECODE'});

        sourceAuthActionLogin = axios.CancelToken.source();
        let config = {cancelToken: sourceAuthActionLogin.token};
        let bodyFormData = new FormData();
        bodyFormData.append("login",loginPayload.phone);
        bodyFormData.append("code",loginPayload.passcode);
        bodyFormData.append("password","");

        let response = await axios.post(authConfig.urlLoginPhoneCode, bodyFormData, config);
        let response_data = await response;
        result = processResponse(response_data,loginPayload);

        if(result && result.data && result.data.data){
            return buildPrincipal(result, dispatch);
        }else {
            //console.log('**** ERROR LOGIN_PHONECODE ****');
            //console.log(result);
            //console.log("*******************************")
            dispatch({type: 'LOGIN_PHONECODE_ERROR',error:result.error});
            return result;
        }

    }catch(e){

        console.log('**** ERROR LOGIN_PHONECODE 2 ****');
        result.error = {code:e.code, msg:e.message, descr: e.description};
        dispatch({type:'LOGIN_PHONECODE_ERROR',error:result.error});
    }

    return result;
}

export async function loginUser(dispatch,loginPayload){

    let result = {};

    try {

        dispatch({type:'REQUEST_LOGIN'});

        sourceAuthActionLogin = axios.CancelToken.source();
        let config = {cancelToken: sourceAuthActionLogin.token, 'csrf': Cookies.get('csrftoken')};
        let bodyFormData = new FormData();
        bodyFormData.append("login",loginPayload.login_val);
        bodyFormData.append("password",loginPayload.password_val);
        let response = await axios.post(authConfig.urlLogin, bodyFormData, config);
        let response_data = await response;


        //console.log('**** SUCCESS LOGIN AWAIT ****');
        //console.log(response_data);
        //console.log('***********************')

        result = processResponse(response_data,loginPayload);
        if(result && result.data && result.data.data){
            return buildPrincipal(result, dispatch);
        }else{
            // если пользователя не было в ответе на логин
            //console.log('**** ERROR LOGIN ****');
            dispatch({type: 'LOGIN_ERROR',error:result.error});
            return result;
        }


   } catch(e){
        let errorJ = e.toJSON();

        result.error = {code:e.code, msg:e.message, descr: e.description};

        console.log('**** CATCH ERROR LOGIN ****');
        console.log(errorJ);
        console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
        console.log(result.error);
        console.log('*********************');

        dispatch({type:'LOGIN_ERROR',error:result.error});
   }

   return result;
}

/**
 * Проверка пользователя, выполняется при каждой загрузке страницы
 * @param dispatch
 * @param checkPayload
 * @returns {Promise<{data: null, error: null, parameters: null}>}
 */
export async function checkUser(dispatch, checkPayload){
    let result = {};
    let headers = checkPayload?.headers;
    if(headers === undefined){
        headers = {}
    }

    const QUERY_PRINCIPAL_STR = "query {principal{name,phone,rules,rights,hashID}}";
    let bodyFormData = new FormData();
    bodyFormData.append("query",QUERY_PRINCIPAL_STR);
    sourceAuthActionLogin = axios.CancelToken.source();

    headers.csrf =  await Cookies.get('csrftoken');

    let config = {
        cancelToken: sourceAuthActionLogin.token,
        headers: headers,
    };

    try {


        let response = await axios.post (authConfig.urlGraphQLPublic,bodyFormData, config);
        let response_data = await response;
        let params = null;
        if(response_data) params = response_data.config;
        //console.log('*************** ACTION RESPONSE *************');
        //console.log(response_data);
        //console.log('*********************************************');

        result = processGQResponse(response_data,params);

        if(result){

            //console.log('-1-');
            if(result && !result.error && result.data && result.data.principal){
                result.data.data = result.data.principal;
                result = buildPrincipal(result, dispatch);
                // можно было в одну строчку, только не наглядно
                //return buildPrincipal({...result,data:{...result.data, data:result.data.principal}}, dispatch);

                // подтянуть данные из локального хранилища
                let hashID = result.data.data.hashID;
                if(hashID){
                    // получить из локального хранилища данные этого пользователя по его хешу
                    let lsItem = localStorage.getItem("user_" + hashID);
                    if(lsItem){
                        result.data.data.localProperties = JSON.parse(lsItem);
                    }
                }
                let city = undefined;
                try {
                    city = JSON.parse(localStorage.getItem("userCity"));
                }catch(e){
                    city = undefined;
                    console.log("get user city catch");
                    console.log(e);
                }

                result.data.data.userCity = city;

                //console.log("dispatch user success");
                //console.log(result);
                //console.log("****************");
                dispatch({type: 'CHECK_SUCCESS',payload:result});
                return result;
            }else{
                //console.log('**** ERROR NOT_SUCCESS CHECK_USER ****');
                //console.log(result.error);
                dispatch({type: 'CHECK_ERROR',error:result?.error});
                return result;
            }

        }else{
            //console.log('**** ERROR CHECK_USER ****');
            //console.log(result.error);
            dispatch({type: 'CHECK_ERROR',error:result?.error});
            return result;
        }
    }catch (e){
        console.log('**** ERROR CHECK_USER 2 ****');
        console.log(e.message);
        result.error = {code:e.code, msg:e.message, descr: e.description};
        dispatch({type:'CHECK_ERROR',error:result.error});
    }

}


export async function cancelLastRequest(){
    CancelLastLoginRequest();
}

export async function logout(dispatch){
    dispatch({type:'LOGOUT'});
    // логаут
}
