import React from 'react';
import axios from 'axios';


export const initialState = {

    userDetails: null,
    loading: false,
    errorMessage : null,
    lastAction:null,

};

export const AuthReducer = (initialState,action) => {

    /*
    console.log("*** AUTH_REDUCER ***");
    console.log(action);
    console.log("********************");
    */

    switch(action.type){

        // авторизация через логин/пароль
        case "REQUEST_LOGIN": {
            return{
                ...initialState,
                loading: true,
                lastAction:action,
            };
        }

        // авторизация через номер телефона. Отправка номера телефона и запрос СМС-кода
        case "REQUEST_LOGIN_PHONE":{
            return{
                ...initialState,
                loading: true,
                lastAction:action,
            };
        }

        // успешный запрос СМС-кода
        case "SUCCESS_LOGIN_PHONE":{
            return{
                ...initialState,
                loading: false,
                lastAction:action,
            };
        }

        // авторизация через номер телефона. Отправка СМС-кода
        case "REQUEST_LOGIN_PHONECODE":{
            return{
                ...initialState,
                loading: true,
                lastAction:action,
            };
        }


        // успешная проверка пользователя
        case "CHECK_SUCCESS":
        // упешная авторизация
        case "LOGIN_SUCCESS": {
            let user = null;
            if(action.payload && action.payload.data){
                user = action.payload.data.data;
            }
            /*
            console.log("LOGIN_SUCCESS");
            console.log(user);
            console.log("**************");
            */
            return{
                ...initialState,
                userDetails: user,
                loading: false,
                errorMessage: null,
                lastError : null,
                lastAction:action,
            }
        }

        case "CHECK_USER": {

            return{
                ...initialState,
                userDetails: null,
                loading: false,
                errorMessage: null,
                lastError: null,
                lastAction:action,
            }
        }

        case "LOGOUT": {
            return{
                ...initialState,
                userDetails: null,
                loading: false,
                errorMessage: null,
                lastError: null,
                lastAction:action,
            }

        }

        // ошибка при получении информации о пользователе
        case "CHECK_ERROR":
            // ошибка аторизации через телефон на стадии отправки кода
        case "LOGIN_PHONECODE_ERROR":
            // ошибка авторизации через телефон на стадии отправки телефона
        case "LOGIN_PHONE_ERROR":
            // ошибка авторизации через логин/пароль
        case "LOGIN_ERROR": {
                return{
                    ...initialState,
                    userDetails: null,
                    loading: false,
                    errorMessage: action.error,
                    lastAction: action,
            };
        }


        default:{
            throw new Error (`Unhandled action type: ${action.type}`);
        }
    }
}