import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Toast, ToastBody } from 'react-bootstrap';
import classNames from 'classnames';

const MpToastComponent = (props) => {
    let bg = 'bg-white';
    let text_color = 'text-black';
    let color_logo = '#000';
    let close_color = 'btn-close-dark';

    // оформление в зависимости от типа
    switch (props.type) {
        case 'danger':
            bg = 'bg-danger';
            text_color = 'text-white';
            color_logo = '#fff';
            close_color = 'btn-close-white';
            break;
        case 'warning':
            bg = 'bg-warning';
            text_color = 'text-white';
            color_logo = '#fff';
            close_color = 'btn-close-white';
            break;
    }

    let mainClassName = classNames(props.className, text_color);

    return (
        <Toast show={props?.show} bg={props?.type} className={mainClassName}>
            <ToastBody>
                <div className="row gx-4">
                    <div className="col-auto">
                        <svg className="d-block" width="24" height="24">
                            <use xlinkHref="#icon-info"/>
                        </svg>
                    </div>
                    <div className="col">
                        {props?.message?.split('\n').map((value, key) => {
                            return <div key={key}>{value}</div>;
                        })}
                    </div>
                </div>
            </ToastBody>
        </Toast>
    );
};

export default MpToastComponent;