
export const authConfig = {
    urlLogin : "/ajax/login",
    urlLoginPhone : "/ajax/login_get_code",
    urlLoginPhoneCode:"/ajax/login",
    urlRegPhone : "/ajax/reg_get_code",
    urlRegPhoneCode: "/ajax/reg",
    urlLogout:"/logout",
    urlGraphQLPublic:"/front2api/graphql/public", }

/*
// backoffice path
export const authConfig = {
    urlLogin : '/ajax/login',
    urlLoginPhone : '/ajax/login_get_code',
    urlLoginPhoneCode:'/ajax/login',
    urlLogout:'/logout',
    urlGraphQLPublic:'/front2api/graphql/public', }*/