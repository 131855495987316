import React, {useEffect, useState} from "react";

export const DownTimer = (props) => {

    const [resendPasscodeCounter, setResendPasscodeCounter] = useState(props.startValue);
    const onDoneCallback =  props.onDone;

    useEffect( () => {

        let timerIdx = null;
        if(resendPasscodeCounter > 0) {
            timerIdx = setTimeout(()=> setResendPasscodeCounter(resendPasscodeCounter -1),1000);
        }else{

            if (onDoneCallback) {
                onDoneCallback();
            }
        }
        return () => {if(timerIdx)clearTimeout(timerIdx)}
    });


    //console.log('init val: ' + resendPasscodeCounter);

    return (
        <span>{resendPasscodeCounter}</span>
    )
};

