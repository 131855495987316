import React from 'react';
import PropTypes from 'prop-types';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
// import {Button} from 'reactstrap';
import Button from 'react-bootstrap/Button';

export const LinkButton = (props) => {
    const {
        //history,
        //location,
        //match,
        //staticContext,
        to,
        onClick,
        ...rest
    } = props

    //const history = useHistory();
    //const location = useLocation();
    //const match = useRouteMatch();
    //const staticContext = useParams();
    const navigate = useNavigate();




    return (
        <Button
            {...rest}
            onClick={(event) => {
                onClick && onClick(event)
                navigate(to)
            }}
        />
    )
}

LinkButton.propTypes ={
    to: PropTypes.string.isRequired,
    children : PropTypes.node.isRequired
}

export default LinkButton;