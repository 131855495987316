import React, {useEffect, useReducer, useState} from "react";
//import {Input} from "reactstrap";
//import {Input} from 'react-bootstrap'
//import InputMask from "react-input-mask";
import InputMask from "@mona-health/react-input-mask";

import Form from 'react-bootstrap/Form';

export const ControlledMaskedInput = (props, ref) => {
    const { innerRef,  ...inputProps } = props;

    return (
        <Form.Control ref={innerRef} as={InputMask} {...inputProps} />

    )
}

export const MaskedInput = (props, ref) => {

    const { onChange, innerRef,  ...inputProps } = props;
    const [value, setValue] = useState(props.value);

    function inputChange(e){
        setValue(e.target.value);

        if(onChange){
            console.log('IPM CHANGE');
            onChange(e);
        }
    }


    /*
    useEffect(() => {
        console.log('IPM VALUE', '['+props.value+']', value);
    })
    */

    /*
       <InputMask className="form-control" type="text"  {...inputProps} value={value}  onChange={inputChange}/>
    */

    return (
        <Form.Control ref={innerRef} as={InputMask} {...inputProps} value={value}  onChange={inputChange}/>

    )

}

