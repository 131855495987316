import React, {useEffect, useRef, useState} from 'react';
import {Toast} from 'bootstrap';


export const MpToast = (props) => {
    const toastRef = useRef();

    let bg = 'bg-white';
    let text_color = 'text-black';
    let color_logo = '#000';
    let close_color = 'btn-close-dark';

    // оформление в зависимости от типа
    switch (props.type) {
        case 'danger':
            bg = 'bg-danger';
            text_color = 'text-white'
            color_logo = '#fff';
            close_color = 'btn-close-white'
            break;
        case 'warning':
            bg = 'bg-warning';
            text_color = 'text-white'
            color_logo = '#fff';
            close_color = 'btn-close-white'
            break;
    }

    useEffect(() => {
        let myToast = toastRef.current
        var toastEl = document.getElementById('myToast');
        let bsToast = Toast.getInstance(myToast)

        if (!bsToast) {
            // initialize Toast
            bsToast = new Toast(myToast, {autohide: true})
            bsToast.hide()
        } else {
            props.toast ? bsToast.show() : bsToast.hide()
        }
    })

    return (
        <div
            className={`${bg} position-fixed top-0 start-50 translate-middle-x m-3 toast align-items-center text-white border-0 fade hide`}
            role="alert" aria-live="assertive" aria-atomic="true" ref={toastRef} style={{'zIndex': 100}}>
            <div className="d-flex position-relative">
                <div className={`toast-body ${text_color}`}>
                    <div className="">
                        <div className="row pe-8">
                            <div className="col-auto">
                                <svg className="me-2" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.25 9.75H10.75C10.3358 9.75 10 10.0858 10 10.5C10 10.9142 10.3358 11.25 10.75 11.25H11.5V16.5H10.75C10.3358 16.5 10 16.8358 10 17.25C10 17.6642 10.3358 18 10.75 18H13.75C14.1642 18 14.5 17.6642 14.5 17.25C14.5 16.8358 14.1642 16.5 13.75 16.5H13V10.5C13 10.0858 12.6642 9.75 12.25 9.75ZM10.75 7.125C10.75 7.74632 11.2537 8.25 11.875 8.25C12.4963 8.25 13 7.74632 13 7.125C13 6.50368 12.4963 6 11.875 6C11.2537 6 10.75 6.50368 10.75 7.125Z"
                                          fill={color_logo}/>
                                </svg>
                            </div>
                            <div className="col pe-4 ps-0">
                                {props.message}
                            </div>
                        </div>
                        <div className="position-absolute end-0 top-0 p-3">
                            <button type="button" className={`btn-close ${close_color} me-2`} data-bs-dismiss="toast"
                                    aria-label="Close"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

// export default MpToast;
