
export function getPhoneValue(ref){
   let val = "" + getInputValue(ref);
   val = val.replace(/[^0-9+]/g, '');
   return val;
}

export function getInputValue(ref){
    let ret = '';
    if(ref&&ref.current){

        let inputNode = null;
        
        if(typeof ref.current.getInputDOMNode === 'function'){
            inputNode = ref.current.getInputDOMNode();
        }

        if(inputNode){
            ret = inputNode.value;
        }else{
            ret = ref.current.value;
        }
    }

    return ret;
}

export function setInputValue(ref,value){
    //console.log('setInputValue begin');
    if(value){
        //console.log('found value: ' + value);
        if(ref&&ref.current){
            //console.log('found current')
            let inputNode = null;
            
            if(typeof ref.current.getInputDOMNode === 'function'){
                inputNode = ref.current.getInputDOMNode();
            }

            if(inputNode){
                //console.log('found input node');
                inputNode.value = value;
            }else{
                //console.log('input node not found');
                ref.current.value = value;
            }

            //console.log('value was set');
        }
    }
    //console.log('setInputValue end')
}

export function setInputFocus(ref){

    if(ref&&ref.current){
        // для MaskInput
        let inputNode = null;
        
        if(typeof ref.current.getInputDOMNode === 'function'){
            inputNode = ref.current.getInputDOMNode();
        }

        if(inputNode){
            inputNode.focus();
        }else{
            //console.log('setInputFocus',ref.current);
            // temp_comment           
            //ref.current.focus = true;
            ref.current.focus();
        }
    }
}

export function buildError(response){
    let errorMsg = 'Ошибка при авторизации';
    if(response&&response.error){
        if(response.error.msg && response.error.msg !== ''){
            errorMsg = response.error.msg;
        }

        /*
        if(response.error.code && response.error.code != ""){
            errorMsg = errorMsg+ '\r\nКод ошибки: '+ response.error.code +'';
        }
        */

        if(response.error.descr && response.error.descr !== ''){
            errorMsg = errorMsg + '. ' + response.error.descr;
        }

    }
    return errorMsg;
}

// строит строку с переносами \n из объекта полученного в buildError
export function buildErrorStr(errorMessage){

    // сборка сообщения об ошибке
    let msg = Array();

    let msgRet = '';
    if(errorMessage?.msg){
        msg.push(errorMessage.msg);
    }

    // дополнить подробностями, если они есть
    if(errorMessage?.descr){
        msg.push(errorMessage.descr);
    }

    msg.map( (value) => {
        if(value){
            if(msgRet !== '') msgRet += "\n";
            msgRet += value;
        }
    })
    return msgRet;
}


export function processResponse(response, loginPayload){

    let result = {data:null,error:null,parameters:null};

    if(response && response.data){
        result.raw = response.data;

        if(response.data.result === 'success'){
            result.data = response.data;
        }else{
            let code = "001";
            let msg = "Unknown error";
            let descr = "";
            let trace_code = "";

            if(response.data.code) code = response.data.code;
            if(response.data.mess) msg = response.data.mess;
            if(response.data.descr) descr = response.data.descr;
            if(response.data.trace_code) trace_code = response.data.trace_code;

            result.error = {code: code, msg: msg, descr: descr, trace_code: trace_code};
        }
    }

    if(loginPayload){
        result.parameters = loginPayload;
    }

    if(response && response.error){
        result.error = {code: response.error.code, msg: response.error.msg, descr: response.error.descr };
    }

    if((!result.data)&&(!result.error)){
        result.error = {code:-1, msg:'Неправильный формат ответа', descr: 'Сервер вернул данные которые не могут быть обработаны'};
    }

    /*
    console.log("****REQUEST RESULT****");
    console.log(result);
    console.log("**********************")
    */

    return result;
}

export function processGQResponse(response, requestPayload){

    let result = {data:null,error:null,parameters:null};
    /*
    console.log("****** response GQ *****");
    console.log(response);
    console.log("************************");
   */
    if(response && response.data){
        result.raw = response.data;
        if(response.data.data){
            result.data = response.data.data;
        }
    }

    if(response && response.data && response.data.errors){

        let code = "001";
        let msg = "Unknown error";
        let descr = "";
        let sep = '';

        if(response.data.errors.length > 0){
            let errorObj = response.data.errors[0];
            if(errorObj.extensions && errorObj.extensions.category) code = errorObj.extensions.category;
            if(errorObj.message) msg = errorObj.message;

            if(errorObj.debugMessage) {
                descr += sep + errorObj.debugMessage;
                sep = ' ';
            }

            if(errorObj.path) {
                descr += sep + '[' + errorObj.path + ']';
                sep  = ' ';
            }

            if(errorObj.locations && errorObj.locations.length > 0 ) {
                descr += sep + "column: " + errorObj.locations[0]?.column + " line: " + errorObj.locations[0]?.line;
            }
        }else{
            //console.log("e2");
        }
        result.error = {code: code, msg: msg, descr: descr};
    }
    else{
        //console.log("e1");
    }




    if(requestPayload){
        result.parameters = requestPayload;
    }

    if(response && response.error){
        result.error = {code: response.error.code, msg: response.error.msg, descr: response.error.descr};
    }

    if((!result.data)&&(!result.error)){
        result.error = {code:-1, msg:'Неправильный формат ответа [GQ]', descr: 'Сервер вернул данные которые не могут быть обработаны'};
     }

    return result;
}

export  function  parseUrlParams(){

    let params = location.search;
    params = params.substr(1);
    //console.log(params);
    let paramList = params.split('&');
    //console.log(paramList);

    let obj = {};

    paramList.map( (value,index) => {
        let splitArr = value.split(/\=(.+)/);
        //console.log(splitArr);
        const [paramName,paramValue] = splitArr
        //console.log(paramName + ' ::: ' + paramValue);
        obj[paramName] = paramValue;
        //console.log(obj);
    })

    return obj;
}

export function addUrlParams(url, paramsObj){
    url = url.replace('//','/');
    let paramStr = '';
    //console.log("========== paramsObj =============");
    //console.log(paramsObj);
    if(paramsObj !== undefined){
        if(typeof paramsObj === 'object'){
            let key = '';
            for(key in paramsObj){
                if((key !== '') && (paramsObj[key]) !== undefined){
                    if(paramStr !== '') paramStr = paramStr + '&';
                    paramStr = paramStr + key + '=' + paramsObj[key]
                }
            }
        }
    }

    if(paramStr !== ''){
        url = url + "?" + paramStr;
    }

    //console.log(url);
    //console.log("============ paramObj ============");
    return url;
}
